import React from 'react';
import { Link } from 'gatsby';

type Props = {
	currentPage: number;
	numPages: number;
	readonly type?: string;
};

const Pagination: React.FC<Props> = ({ currentPage, numPages, type }) => {

	return (
		<div className="w-full bg-black grid grid-cols-3 items-center my-8 rounded ">
			{currentPage !== 1 && (
				<button className="col-start-1 font-mont text-sm">
					{currentPage -1 === 1 && (
						<Link to={type === 'blog' ? `blog` : `project`}>Back</Link>
					)}

					{currentPage -1 > 1 && (
						<Link to={type === 'blog' ? `blog/${currentPage-1}` : `project/${currentPage - 1}`}>Back</Link>
					)}

			
				</button>
			)}
			<p className="col-start-2 text-center my-2 font-mont text-sm">
				Page {currentPage} of {numPages}
			</p>
			{currentPage < numPages &&
			numPages !== 1 &&
			 (
				<button className="col-start-3 font-mont text-sm">
					<Link to={`/${type === 'blog' ? 'blog' : 'project'}/${currentPage + 1}`}>Next</Link>
				</button>
			)}
		</div>
	);
};

export default Pagination;
