import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import Layout from "../components/layout"
import Pagination from "../components/Pagination"
import { Player, LoadingSpinner, BigPlayButton, PosterImage } from "video-react"
import "../../node_modules/video-react/dist/video-react.css" // import css

interface Props {
  data: PagePropsData
  pageContext: {
    currentPage: number
    limit: number
    start: number
    numPages: number
  }
}

interface PagePropsData {
  allStrapiProject: {
    edges: {
      node: {
        id: string
        title: string
        content: string
        excerpt: string
        poster_image: {
          childImageSharp: {
            fluid: FixedObject
          }
          publicURL: string
        }
        project_video: {
          publicURL: string
        }
        slug: string
        projectcategory: {
          name: string
        }
        published_at: string
      }
    }
  }
}

export const query = graphql`
  query($start: Int!, $limit: Int!) {
    allStrapiProject(
      sort: { fields: [published_at], order: DESC }
      limit: $limit
      skip: $start
    ) {
      edges {
        node {
          id
          title
          poster_image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          project_video {
            publicURL
          }
          slug
          content
          excerpt
          projectcategory {
            name
          }
          published_at
        }
      }
    }
  }
`

const ProjectList: React.FC<Props> = ({ data, pageContext }) => {
  const currentPage = pageContext.currentPage
  const numPages = pageContext.numPages
  const projects = data.allStrapiProject.edges

  return (
    <Layout>
      <h1 className="font-monda">PROJECTS</h1>
      <div className="border-white border-b-4" />
      <Pagination
        currentPage={currentPage}
        numPages={numPages}
        type="project"
      />
      {projects.map(project => {
        const excerpt = project.node.excerpt.slice(0, 170) + "..."
        return (
          <div
            key={project.node.id}
            className="mb-4 border-b pb-4 border-white sm:my-6 flex flex-col sm:items-top sm:flex-row "
          >
            <div className="flex-1 sm:pr-2">
              <h4 className="font-monda mt-4 mb-2 text-xl sm:mt-0">
                <Link to={`/project/${project.node.slug}`}>
                  {project.node.title}
                </Link>
              </h4>

              <p className="text-sm font-mont lg:text-md">{excerpt}</p>
              <div className="flex flex-col justify-between ">
                <div className="">
                  <p className="my-0 font-mont text-sm font-bold">
                    {project.node.projectcategory.name}
                  </p>
                  <p className="mb-0 font-mont text-sm">
                    John Phung &#x272A; {}
                    {project.node.published_at}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-1 mt-4 sm:mt-0">
              <Player
                src={project.node.project_video.publicURL}
                poster={project.node.poster_image.publicURL}
              >
                <BigPlayButton position="center" />
                <LoadingSpinner />
              </Player>
              <button className="bg-transparent border-white border text-white p-2 w-full text-sm mt-2 sm:mt-2 focus:outline-none">
                <Link
                  className="font-mont "
                  to={`/project/${project.node.slug}`}
                >
                  View Detail &#xbb;
                </Link>
              </button>
            </div>
          </div>
        )
      })}

      <Pagination
        currentPage={currentPage}
        numPages={numPages}
        type="project"
      />
    </Layout>
  )
}

export default ProjectList
